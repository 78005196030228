var __jsx = React.createElement;
import React from 'react';
import ErrorPage from 'next/error';
import { captureException } from '@sentry/nextjs';
export default class MyError extends React.Component {
  static getInitialProps(_ref) {
    var res = _ref.res,
        err = _ref.err;
    var statusCode = res ? res.statusCode : err ? err.statusCode : null;

    if (statusCode !== 200) {
      var _err$message;

      console.log({
        statusCode,
        err
      });

      if (statusCode === 404) {// withScope((scope) => {
        //   scope.setLevel(Severity.Warning)
        //   captureException(new Error(err?.message ?? statusCode))
        // })
      } else captureException(new Error((_err$message = err === null || err === void 0 ? void 0 : err.message) !== null && _err$message !== void 0 ? _err$message : statusCode));
    }

    return {
      statusCode,
      error: err
    };
  }

  render() {
    return __jsx(ErrorPage, {
      statusCode: this.props.statusCode
    });
  }

}